
function d3bubble(scope, el, datasearch){

        el=el[0];

        var data = scope.datasearch;

        var color1 = d3.scale.category20(),
            color2 = d3.scale.category20b(),
            color3 = d3.scale.category20c();

        function fillcolor(value) {
            var val = value % 60;

            if (val < 20)
                return color1(val);
            else if (val < 40)
                return color2(val);
            else
                return color3(val);
        }
  //el.offsetWidth,

    var width_needed = $("#bubblespace").width();
    var heigh_needed = $(window).height()-100;
    var diameter = Math.min(width_needed, heigh_needed)
            format = d3.format(",d");

        var bubble = d3.layout.pack()
            .sort(null)
            .size([diameter, diameter])
            .padding(1.5);




//        d3.json("/api/search", function(error, root) {

//    scope.$watch(datasearch['ngModel'], function (data) {

        d3.select("svg")
            .remove();

        var svg = d3.select(el).append("svg")
            .attr("width", diameter)
            .attr("height", diameter)
            .attr("class", "bubble");

        var tooltip = d3.select("body")
            .append("div")
            .attr("class", "infomsg")
            .text("tooltip");


          var node = svg.selectAll(".node")
              .data(bubble.nodes(classes(scope.datasearch))
              .filter(function(d) { return !d.children; }))
					.enter().append("g")
              .attr("class", "node")
              .attr("transform", function(d) { return "translate(" + d.x + "," + d.y + ")"; });


          node.append("circle")
              .attr("r", function(d) { return d.r; })
              .style("fill", function(d, i) { return fillcolor(i); })
              .on("mouseover", function(d) {
                  tooltip.html("<b>"+d.methodName+"</b><br />"+d.methodShortDescription);
                      tooltip.style("display", "block");
              })
              .on("mousemove", function() {
                if($(window).width() < 300+d3.event.pageX) {
                  return tooltip.style("top", (d3.event.pageY-10)+"px").style("left",(d3.event.pageX-(10+300))+"px");
                } else {
                  return tooltip.style("top", (d3.event.pageY-10)+"px").style("left",(d3.event.pageX+10)+"px");
                }
              })
              .on("mouseout", function(){return tooltip.style("display", "none");})
              .on("click", function(d){ window.location.href = "/method/"+d.methodId });

          node.append("text")
              .attr("dy", ".3em")
              .style("text-anchor", "middle")
              .style("pointer-events", "none")
              .text(function(d) { return d.methodName.substring(0, d.r / 3); });

//    });



scope.$watch(datasearch['ngModel'], function (data) {
    node.data(bubble.nodes(classes(data)).filter(function(d) { return !d.children; }))
        .transition().duration(500)
        .attr("transform", function(d) { return "translate(" + d.x + "," + d.y + ")"; });


    node.select("circle")
        .transition().duration(500)
        .attr("r", function(d) { return d.r; });

    node.select("text")
        .text(function(d) { return d.methodName.substring(0, d.r / 3); });
});



        // Returns a flattened hierarchy containing all leaf nodes under the root.
        function classes(root) {
          var classes = [];

          function recurse(name, node) {
            if (node.children) node.children.forEach(function(child) { recurse(node.name, child); });
              else classes.push({packageName: name, methodId: node.id, methodName: node.name, methodShortDescription: node.short_description, value: node.size});
          }

          recurse(null, root);
          return {children: classes};
        }


    }

  angular.module('engageApp')

    .directive('d3bubble', function($timeout) {

    		return {
						restrict: 'EA',
            transclude: true,
						link: d3bubble
				};
		});
