var engageApp = angular.module('engageApp', ['ui.router', 'checklist-model']);

engageApp.factory("qFactory", function($http, $q) {

		var factory = {};
    var urlBase = '/api/';

    factory.getInit = function () {
        return $http.post(urlBase+"init");
    };
    factory.getSearch = function (criteria) {
        return $http.post(urlBase+"search", criteria);
    };
    factory.getItem = function (item_id) {
        return $http.post(urlBase+"item"+"/"+item_id);
    };

		factory.postCustomer = function(customer) {
        return $http.post('/services', {
            name : customer.name,
            city : customer.city
        });
		}

		return factory;
});


engageApp.service("serv", function($http, $q, $scope, $location) {

		var serv = this;
		serv.getAllTasks = function() {
				var defer = $q.defer();

				$http.get("/")
						.success(function(res) {
								defer.resolve(res);
						})
						.error(function(err, status) {
								defer.reject(err);
						});

				return defer.promise;
		}

		serv.createTask = function(task) {
				var defer = $q.defer();
				$http.post("/", task)
						.success(function(res) {
								defer.resolve(res);
						})
						.error(function(err, status) {
								defer.reject(err);
						});

				return defer.promise;
		}

		serv.deleteTask = function(task) {
				var defer = $q.defer();
				$http.delete("/", task)
						.success(function(res) {
								defer.resolve(res);
						})
						.error(function(err, status) {
								defer.reject(err);
						});

				return defer.promise;
		}

		return serv;
});

engageApp.config(function($stateProvider, $urlRouterProvider, $locationProvider) {
		// For any unmatched url, redirect to /state1
		$locationProvider.html5Mode(true);

		$stateProvider
				.state('home', {
						url: "/",
            title: "ActionCatalogue",
						templateUrl: "partials/home"
				})
				.state('contact', {
						url: "/contact",
            title: "ActionCatalogue - Contact",
						templateUrl: "partials/contact"
				})
				.state('about', {
						url: "/about",
            title: "ActionCatalogue - About",
						templateUrl: "partials/about"
				})
				.state('search', {
						url: "/search",
            title: "ActionCatalogue - methods",
						templateUrl: "partials/methods",
						controller: ['$scope', 'qFactory', '$sce', '$anchorScroll', function($scope, qfactory, $sce,  $anchorScroll) {
                $anchorScroll();

                $scope.$on('$locationChangeStart', function( event ) {
                    $('.infomsg').css("display", "none");
                    $('#importanceModal').foundation('reveal', 'close');
                });


                $scope.practical_considerations = 0;
                $scope.participants = 0;
                $scope.content_of_method = 0;

                $scope.varset = function(str) {
                    if(str == "practical_considerations") {
                        $scope.practical_considerations = !$scope.practical_considerations;
                        $("#div_practical_considerations").toggleClass("hide");
                    } else if(str == "participants") {
                        $scope.participants = !$scope.participants;
                        $("#div_participants").toggleClass("hide");
                    } else if(str == "content_of_method") {
                        $scope.content_of_method = !$scope.content_of_method;
                        $("#div_content_of_method").toggleClass("hide");
                    }

                    //console.log(practical_considerations);
                }

                $scope.listing = 0;
                $scope.clearfilters = function(value) {
                    $scope.searchtext = "";
                    resetfilters();
                    $('input[type="range"]').val(0).change();
                }
                $scope.selected_listing = function(value) {
                    $scope.listing = value;
                    if(value) {
                        $(".type-menu .menuitem.item1").toggleClass("active", "remove");
                        $(".type-menu .menuitem.item2").toggleClass("active", "add");
                    } else {
                        $(".type-menu .menuitem.item1").toggleClass("active", "add");
                        $(".type-menu .menuitem.item2").toggleClass("active", "remove");
                    }
                    //console.log($scope.listing);
                };

                $scope.objective_applications = [
								    {id: 342, text: "Policy formulation"},
										{id: 343, text: "Programme development"},
										{id: 344, text: "Project definition"},
										{id: 345, text: "Research activity"},
										{id: 346, text: "Political empowerment of people"}
                ];

                $scope.level_involvements = [
								    {id: 349, text: "Dialogue"},
										{id: 350, text: "Consulting"},
										{id: 351, text: "Involving"},
										{id: 352, text: "Collaborating"},
										{id: 353, text: "Empowering"},
                    {id: 354, text: "Direct decision"}
                ];

                $scope.geographical_scopes = [
								    {id: 391, text: "International"},
										{id: 392, text: "EU"},
										{id: 393, text: "National"},
										{id: 394, text: "Regional"},
										{id: 395, text: "Local"}
                ];
                $scope.societal_challenges = [
								    {id: 396, text: "Health, demographic change and wellbeing"},
										{id: 397, text: "Food security, sustainable agriculture, marine and maritime research and the bio-economy"},
										{id: 398, text: "Secure, clean and efficient energy"},
										{id: 399, text: "Smart, green and integrated transport"},
										{id: 400, text: "Climate action, resource efficiency and raw materials"},
                    {id: 401, text: "Inclusive, innovative and reflective societies"},
                    {id: 402, text: "Secure societies to protect freedom and security of Europe and its citizens"},
                    {id: 403, text: "Others"}
                ];

                $scope.engaged_stakeholders_organisers = [
								    {id: 355, text: "CSO's"},
										{id: 358, text: "Policy-makers"},
										{id: 361, text: "Researchers"},
										{id: 364, text: "Citizens"},
										{id: 367, text: "Affected"},
                    {id: 370, text: "Consumers"},
                    {id: 373, text: "Employees"},
                    {id: 376, text: "Users"},
                    {id: 379, text: "Industry"}
                ];

                $scope.engaged_stakeholders_directparticipants = [
								    {id: 356, text: "CSO's"},
										{id: 359, text: "Policy-makers"},
										{id: 362, text: "Researchers"},
										{id: 365, text: "Citizens"},
										{id: 368, text: "Affected"},
                    {id: 371, text: "Consumers"},
                    {id: 374, text: "Employees"},
                    {id: 377, text: "Users"},
                    {id: 380, text: "Industry"}
                ];

                $scope.engaged_stakeholders_beneficiaries = [
								    {id: 357, text: "CSO's"},
										{id: 360, text: "Policy-makers"},
										{id: 363, text: "Researchers"},
										{id: 366, text: "Citizens"},
										{id: 369, text: "Affected"},
                    {id: 372, text: "Consumers"},
                    {id: 375, text: "Employees"},
                    {id: 378, text: "Users"},
                    {id: 381, text: "Industry"}
                ];


                $scope.$on('LOAD', function() { $scope.loading = true; });
                $scope.$on('UNLOAD', function() { $scope.loading = false; });

                $scope.$emit('LOAD');
                qfactory.getInit().then(
                    function(response) {
                        data = response.data
                        $scope.datasearch = response.data;
                        $scope.$emit('UNLOAD');
                    },
                    function(error) {
                        loaded_data = false;
                        console.log("The request failed: " + error);
                    });


                $scope.skill_subject_matters = [
								    {id: 415, text: "No such skills required"},
										{id: 416, text: "Basic"},
										{id: 417, text: "Intermediate"},
										{id: 418, text: "Advanced"}
                ];

                $scope.skill_its = [
								    {id: 419, text: "No such skills required"},
										{id: 420, text: "Basic"},
										{id: 421, text: "Intermediate"},
										{id: 422, text: "Advanced"}
                ];

                $scope.skill_facilitations = [
								    {id: 423, text: "No such skills required"},
										{id: 424, text: "Basic"},
										{id: 425, text: "Intermediate"},
										{id: 426, text: "Advanced"}
                ];

                $scope.skill_events = [
								    {id: 427, text: "No such skills required"},
										{id: 428, text: "Basic"},
										{id: 429, text: "Intermediate"},
										{id: 430, text: "Advanced"}
                ];

                $scope.skill_project_managements = [
								    {id: 431, text: "No such skills required"},
										{id: 432, text: "Basic"},
										{id: 433, text: "Intermediate"},
										{id: 434, text: "Advanced"}
                ];


                $scope.practical_number_participants = [
								    {id: 506, text: "Not Applicable"},
										{id: 501, text: "Less than 10"},
								    {id: 502, text: "10 to 25"},
                    {id: 503, text: "26 to 100"},
										{id: 504, text: "101 to 300"},
										{id: 505, text: "More than 300"}
                ];

                $scope.practical_retaining_participants = [
								    {id: 451, text: "Not Applicable"},
								    {id: 232, text: "Completely replacing all participants over the course of the process is fine "},
								    {id: 233, text: "A small core of participants need to remain the same while the remainder of participants can be replaced "},
										{id: 234, text: "There are certain points in the process where replacing participants is possible"},
										{id: 235, text: "The great majority of participants should be retained throughout the process "},
										{id: 236, text: "The same set of participants need to stay involved throughout the process "}
                ];

                $scope.practical_budget_needs = [
								    {id: 513, text: "Not Applicable"},
								    {id: 508, text: "Less than 10.000€"},
								    {id: 509, text: "10.000€ to 35.000€"},
										{id: 510, text: "35.001€ to 65.000€ "},
										{id: 511, text: "65.001€ to 140.000€"},
										{id: 512, text: "More than 140.000€"}
                ];

                $scope.practical_time_needed_for_execution_of_methods = [
								    {id: 520, text: "Not Applicable"},
										{id: 515, text: "Less than 3 months "},
								    {id: 516, text: "3 to 6 months"},
								    {id: 517, text: "6 to 12 months"},
										{id: 518, text: "1 to 2 years"},
										{id: 519, text: "More than 2 years"}
                ];

                $scope.practical_duration_of_participant_involvements = [
								    {id: 527, text: "Not Applicable"},
										{id: 522, text: "Participation takes 10 minutes or less"},
								    {id: 523, text: "Participation takes between 10 minutes to 1 hour"},
								    {id: 524, text: "Participation takes between 1 to 3 hours"},
										{id: 525, text: "Participation takes between 3 hours to a day"},
										{id: 526, text: "Participation takes place over a number of days or is ongoing"}
                ];

                $scope.practical_contexts = [
								    {id: 555, text: "Not Applicable"},
								    {id: 550, text: "Face-to-face - does not work online"},
								    {id: 551, text: "Hybrid - includes face-to-face and online elements "},
										{id: 552, text: "Adaptable - Can be run online and/or face-to-face"},
										{id: 553, text: "Can only be run online "}
                ];

                $scope.participants_gather_datas = [
								    {id: 446, text: "Not Applicable"},
								    {id: 207, text: "The process does not involve participants in gathering data"},
								    {id: 208, text: "Method executors involve participants in gathering some of the data"},
										{id: 209, text: "Participants and method executors cooperate evenly in gathering data"},
										{id: 210, text: "Data is gathered primarily by participants, with method executors assisting"},
										{id: 211, text: "Participants are solely responsible for gathering data"}
                ];

                $scope.participants_analyse_datas = [
								    {id: 447, text: "Not Applicable"},
								    {id: 212, text: "The process does not involve participants in the analysis of data"},
								    {id: 213, text: "The process only allows participants to read analysis of data"},
										{id: 214, text: "The process allows participants to comment on analysis of data"},
										{id: 215, text: "The process allows participants to review analysis of data"},
										{id: 216, text: "The process allows participants to be deeply involved in the analysis of data"}
                ];

                $scope.participants_academic_experts = [
								    {id: 454, text: "Not Applicable"},
										{id: 247, text: "Academic experts should not be involved in the process"},
								    {id: 248, text: "Academic experts could be involved in the preparation of the process"},
								    {id: 249, text: "Academic experts could be involved during the process"},
										{id: 250, text: "Academic experts could be involved after the process"},
										{id: 251, text: "Academic experts could be involved at multiple stages of the process"}
                ];

                $scope.participants_stakeholders = [
								    {id: 455, text: "Not Applicable"},
										{id: 252, text: "Stakeholders should not be involved in the process"},
								    {id: 253, text: "Stakeholders could be involved in the preparation of the process"},
								    {id: 254, text: "Stakeholders could be involved during the process"},
										{id: 255, text: "Stakeholders could be involved after the process"},
										{id: 256, text: "Stakeholders could be involved at multiple stages of the process"}
                ];

                $scope.participants_citizens = [
								    {id: 456, text: "Not Applicable"},
										{id: 257, text: "Citizens should not be involved in the process"},
								    {id: 258, text: "Citizens could be involved in the preparation of the process"},
								    {id: 259, text: "Citizens could be involved during the process"},
										{id: 260, text: "Citizens could be involved after the process"},
										{id: 261, text: "Citizens could be involved at multiple stages of the process"}
                ];

                $scope.participants_users = [
								    {id: 457, text: "Not Applicable"},
										{id: 262, text: "Users should not be involved in the process"},
								    {id: 263, text: "Users could be involved in the preparation of the process"},
								    {id: 264, text: "Users could be involved during the process"},
										{id: 265, text: "Users could be involved after the process"},
										{id: 266, text: "Users could be involved at multiple stages of the process"}
                ];

                $scope.participants_policy_makers = [
								    {id: 458, text: "Not Applicable"},
								    {id: 267, text: "Policy makers should not be involved in the process"},
								    {id: 268, text: "Policy makers could be involved in the preparation of the process"},
										{id: 269, text: "Policy makers could be involved during the process"},
										{id: 270, text: "Policy makers could be involved after the process"},
										{id: 271, text: "Policy makers could be involved at multiple stages of the process"}
                ];

                $scope.participants_digital_skills = [
								    {id: 534, text: "Not Applicable"},
										{id: 529, text: "No computer skills needed"},
								    {id: 530, text: "Limited computer skills needed"},
								    {id: 531, text: "Basic computer skills needed"},
										{id: 532, text: "Intermediate computer skills needed"},
										{id: 533, text: "Advanced computer skills needed"}
                ];

                $scope.participants_literacy_skills = [
								    {id: 541, text: "Not Applicable"},
								    {id: 536, text: "No literacy skills needed"},
								    {id: 537, text: "Limited literacy skills needed "},
										{id: 538, text: "Basic literacy skills needed "},
										{id: 539, text: "Intermediate literacy skills needed "},
										{id: 540, text: "Advanced literacy skills needed "}
                ];

                $scope.com_legal_issues = [
								    {id: 462, text: "Not Applicable"},
								    {id: 287, text: "The method cannot assess legal issues"},
								    {id: 288, text: "The method can provide limited assessment on legal issues"},
										{id: 289, text: "The method can provide basic assessment on legal issues"},
										{id: 290, text: "The method can provide intermediate assessment on legal issues"},
										{id: 291, text: "The method can provide advanced assessment on legal issues"}
                ];

                $scope.com_social_issues = [
								    {id: 463, text: "Not Applicable"},
								    {id: 292, text: "The method cannot assess social issues"},
								    {id: 293, text: "The method can provide limited assessment on social issues"},
										{id: 294, text: "The method can provide basic assessment on social issues"},
										{id: 295, text: "The method can provide intermediate assessment on social issues "},
										{id: 296, text: "The method can provide advanced assessment on social issues"}
                ];

                $scope.com_governance_issues = [
								    {id: 464, text: "Not Applicable"},
								    {id: 297, text: "The method cannot assess governance issues"},
								    {id: 298, text: "The method can provide limited assessment on governance issues"},
										{id: 299, text: "The method can provide basic assessment on governance issues"},
										{id: 300, text: "The method can provide intermediate assessment on governance issues "},
										{id: 301, text: "The method can provide advanced assessment on governance issues"}
                ];

                $scope.com_ethics_explicits = [
								    {id: 445, text: "Not Applicable"},
										{id: 202, text: "Outputs do not make ethics and mores explicit at all"},
										{id: 203, text: "Outputs indirectly address ethics and mores"},
								    {id: 204, text: "Outputs superficially cover ethics and mores"},
								    {id: 205, text: "Outputs cover ethics and mores in detail"},
										{id: 206, text: "Outputs primarily address ethics and mores"}
                ];

                $scope.com_heterogeneities = [
								    {id: 459, text: "Not Applicable"},
										{id: 272, text: "The method is based on a paradigmatic scientific approach"},
								    {id: 273, text: "The method can only with difficulty include heterogeneous scientific input"},
								    {id: 274, text: "The method can include heterogeneous scientific input "},
										{id: 275, text: "The method works best with heterogeneous scientific input"},
										{id: 276, text: "The method requires heterogeneous scientific input"}
                ];

                $scope.com_temporal_scopes = [
								    {id: 465, text: "Not Applicable"},
										{id: 302, text: "There is no temporal focus"},
										{id: 303, text: "The method focuses on past events"},
								    {id: 304, text: "The method focuses on concurrent events"},
								    {id: 305, text: "The method focuses on future events"},
										{id: 306, text: "The method has multiple temporal foci"}
                ];

                $scope.com_transparency_of_engagements = [
								    {id: 450, text: "Not Applicable"},
								    {id: 227, text: "The method is premised on being closed and opaque"},
								    {id: 228, text: "The method works best closed and can only be opened up with difficulty"},
										{id: 229, text: "Parts of the process can be opened up and made transparent"},
										{id: 230, text: "The whole process can be made transparent easily"},
										{id: 231, text: "The process requires transparency to function"}
                ];

                $scope.com_openness_of_agendas = [
								    {id: 466, text: "Not Applicable"},
								    {id: 307, text: "The agenda is set in advance by the organisers and cannot be influenced during the process"},
								    {id: 308, text: "The agenda is set in advance by the organisers and can be adjusted slightly "},
										{id: 309, text: "The agenda is set in advance by the organisers and can be changed significantly "},
										{id: 310, text: "The agenda is developed by organiser and participants as the process develops; the final decision rests with the organisers "},
										{id: 311, text: "The agenda is developed by organiser and participants as the process develops; the final decision rests with the participants"}
                ];

                $scope.com_level_of_deliberations = [
								    {id: 469, text: "Not Applicable"},
										{id: 322, text: "The process does not allow for any deliberation or discussion amongst participants "},
										{id: 323, text: "The process only allows limited time for deliberation or discussion amongst participants"},
										{id: 324, text: "The process allows for some deliberation and discussion amongst participants"},
										{id: 325, text: "The process allows substantial time for deliberation and discussion amongst participants ,"},
										{id: 326, text: "The majority of time in the process is spent on deliberation and small group discussions"}
                ];

                function resetfilters() {
                    $scope.search = {
                        objective_applications: [],
                        level_involvements: [],
                        geographical_scopes: [],
                        societal_challenges: [],
                        engaged_stakeholders_organisers: [],
                        engaged_stakeholders_directparticipants: [],
                        engaged_stakeholders_beneficiaries: [],
                        skill_subject_matters: [415],
                        skill_its: [419],
                        skill_facilitations: [423],
                        skill_events: [427],
                        skill_project_managements: [431],
                        practical_number_participants: [506],
                        practical_retaining_participants: [451],
                        practical_budget_needs: [513],
                        practical_time_needed_for_execution_of_methods: [520],
                        practical_duration_of_participant_involvements: [527],
                        practical_contexts: [555],
                        participants_gather_datas: [446],
                        participants_analyse_datas: [447],
                        participants_academic_experts: [454],
                        participants_stakeholders: [455],
                        participants_citizens: [456],
                        participants_users: [457],
                        participants_policy_makers: [458],
                        participants_digital_skills: [534],
                        participants_literacy_skills: [541],
                        com_legal_issues: [462],
                        com_social_issues: [463],
                        com_governance_issues: [464],
                        com_ethics_explicits: [445],
                        com_heterogeneities: [459],
                        com_temporal_scopes: [465],
                        com_transparency_of_engagements: [450],
                        com_openness_of_agendas: [466],
                        com_level_of_deliberations: [469],
                        importance_searchtext: 50,
                        importance_objective_applications: 50,
                        importance_level_involvements: 50,
                        importance_geographical_scopes: 50,
                        importance_organisers: 50,
                        importance_directparticipants: 50,
                        importance_beneficiaries: 50,
                        importance_societal_challenges: 50,
                        importance_skill_subject_matters: 50,
                        importance_skill_its: 50,
                        importance_skill_facilitations: 50,
                        importance_skill_events: 50,
                        importance_skill_project_managements: 50,
                        importance_practical_number_participants: 50,
                        importance_practical_retaining_participants: 50,
                        importance_practical_budget_needs: 50,
                        importance_practical_time_needed_for_execution_of_methods: 50,
                        importance_practical_duration_of_participant_involvements: 50,
                        importance_practical_contexts: 50,
                        importance_participants_gather_datas: 50,
                        importance_participants_analyse_datas: 50,
                        importance_participants_academic_experts: 50,
                        importance_participants_stakeholders: 50,
                        importance_participants_citizens: 50,
                        importance_participants_users: 50,
                        importance_participants_policy_makers: 50,
                        importance_participants_digital_skills: 50,
                        importance_participants_literacy_skills: 50,
                        importance_com_legal_issues: 50,
                        importance_com_social_issues: 50,
                        importance_com_governance_issues: 50,
                        importance_com_ethics_explicits: 50,
                        importance_com_heterogeneities: 50,
                        importance_com_temporal_scopes: 50,
                        importance_com_transparency_of_engagements: 50,
                        importance_com_openness_of_agendas: 50,
                        importance_com_level_of_deliberations: 50
                    };
                }
                resetfilters();

                $scope.importance_range = 0;
                $scope.importance_var = "";
                $scope.importance = function(key) {
                    $scope.importance_var = 'importance_'+key;
                    $scope.importance_range = $scope.search[$scope.importance_var];
                    $("#rangeinput").val($scope.search[$scope.importance_var]).change()
                }

                $scope.importance_change = function() {
                    $scope.search[$scope.importance_var] = $scope.importance_range;
                    dosearch();
                };

                $scope.slide_matters_change = function() {
                    $scope.search.skill_subject_matters= $scope.skill_subject_matters[$scope.skill_subject_matters_slider].id;
                    dosearch();
                };
                $scope.slide_its_change = function() {
                    $scope.search.skill_its = $scope.skill_its[$scope.skill_its_slider].id;
                    dosearch();
                };
                $scope.slide_facilitations_change = function() {
                    $scope.search.skill_facilitations= $scope.skill_facilitations[$scope.skill_facilitations_slider].id;
                    dosearch();
                };
                $scope.slide_events_change = function() {
                    $scope.search.skill_events= $scope.skill_events[$scope.skill_events_slider].id;
                    dosearch();
                };
                $scope.slide_project_managements_change = function() {
                    $scope.search.skill_project_managements= $scope.skill_project_managements[$scope.skill_project_managements_slider].id;
                    dosearch();
                };


                $scope.slide_practical_number_participants_change = function() {
                    $scope.search.practical_number_participants= $scope.practical_number_participants[$scope.practical_number_participants_slider].id;
                    dosearch();
                };

                $scope.slide_practical_retaining_participants_change = function() {
                    $scope.search.practical_retaining_participants= $scope.practical_retaining_participants[$scope.practical_retaining_participants_slider].id;
                    dosearch();
                };

                $scope.slide_practical_budget_needs_change = function() {
                    $scope.search.practical_budget_needs= $scope.practical_budget_needs[$scope.practical_budget_needs_slider].id;
                    dosearch();
                };

                $scope.slide_practical_time_needed_for_execution_of_methods_change = function() {
                    $scope.search.practical_time_needed_for_execution_of_methods= $scope.practical_time_needed_for_execution_of_methods[$scope.practical_time_needed_for_execution_of_methods_slider].id;
                    dosearch();
                };

                $scope.slide_practical_duration_of_participant_involvements_change = function() {
                    $scope.search.practical_duration_of_participant_involvements= $scope.practical_duration_of_participant_involvements[$scope.practical_duration_of_participant_involvements_slider].id;
                    dosearch();
                };

                $scope.slide_practical_contexts_change = function() {
                    $scope.search.practical_contexts= $scope.practical_contexts[$scope.practical_contexts_slider].id;
                    dosearch();
                };

                $scope.slide_participants_gather_datas_change = function() {
                    $scope.search.participants_gather_datas= $scope.participants_gather_datas[$scope.participants_gather_datas_slider].id;
                    dosearch();
                };

                $scope.slide_participants_analyse_datas_change = function() {
                    $scope.search.participants_analyse_datas= $scope.participants_analyse_datas[$scope.participants_analyse_datas_slider].id;
                    dosearch();
                };

                $scope.slide_participants_academic_experts_change = function() {
                    $scope.search.participants_academic_experts= $scope.participants_academic_experts[$scope.participants_academic_experts_slider].id;
                    dosearch();
                };

                $scope.slide_participants_stakeholders_change = function() {
                    $scope.search.participants_stakeholders= $scope.participants_stakeholders[$scope.participants_stakeholders_slider].id;
                    dosearch();
                };

                $scope.slide_participants_citizens_change = function() {
                    $scope.search.participants_citizens= $scope.participants_citizens[$scope.participants_citizens_slider].id;
                    dosearch();
                };

                $scope.slide_participants_users_change = function() {
                    $scope.search.participants_users= $scope.participants_users[$scope.participants_users_slider].id;
                    dosearch();
                };

                $scope.slide_participants_policy_makers_change = function() {
                    $scope.search.participants_policy_makers= $scope.participants_policy_makers[$scope.participants_policy_makers_slider].id;
                    dosearch();
                };

                $scope.slide_participants_digital_skills_change = function() {
                    $scope.search.participants_digital_skills= $scope.participants_digital_skills[$scope.participants_digital_skills_slider].id;
                    dosearch();
                };

                $scope.slide_participants_literacy_skills_change = function() {
                    $scope.search.participants_literacy_skills= $scope.participants_literacy_skills[$scope.participants_literacy_skills_slider].id;
                    dosearch();
                };

                $scope.slide_com_legal_issues_change = function() {
                    $scope.search.com_legal_issues= $scope.com_legal_issues[$scope.com_legal_issues_slider].id;
                    dosearch();
                };

                $scope.slide_com_social_issues_change = function() {
                    $scope.search.com_social_issues= $scope.com_social_issues[$scope.com_social_issues_slider].id;
                    dosearch();
                };

                $scope.slide_com_governance_issues_change = function() {
                    $scope.search.com_governance_issues= $scope.com_governance_issues[$scope.com_governance_issues_slider].id;
                    dosearch();
                };

                $scope.slide_com_ethics_explicits_change = function() {
                    $scope.search.com_ethics_explicits= $scope.com_ethics_explicits[$scope.com_ethics_explicits_slider].id;
                    dosearch();
                };

                $scope.slide_com_heterogeneities_change = function() {
                    $scope.search.com_heterogeneities= $scope.com_heterogeneities[$scope.com_heterogeneities_slider].id;
                    dosearch();
                };

                $scope.slide_com_temporal_scopes_change = function() {
                    $scope.search.com_temporal_scopes= $scope.com_temporal_scopes[$scope.com_temporal_scopes_slider].id;
                    dosearch();
                };

                $scope.slide_com_transparency_of_engagements_change = function() {
                    $scope.search.com_transparency_of_engagements= $scope.com_transparency_of_engagements[$scope.com_transparency_of_engagements_slider].id;
                    dosearch();
                };

                $scope.slide_com_openness_of_agendas_change = function() {
                    $scope.search.com_openness_of_agendas= $scope.com_openness_of_agendas[$scope.com_openness_of_agendas_slider].id;
                    dosearch();
                };

                $scope.slide_com_level_of_deliberations_change = function() {
                    $scope.search.com_level_of_deliberations= $scope.com_level_of_deliberations[$scope.com_level_of_deliberations_slider].id;
                    dosearch();
                };


//                $scope.searchtext_changed = function() { dosearch(); };
                $scope.change_form = function() { dosearch(); };

                function dosearch(){
                    criteria = {};

                    if($scope.searchtext != "") {
                        criteria.searchtext = $scope.searchtext;
                        if($scope.search.importance_searchtext > 1) {
                            criteria.importance_searchtext = $scope.search.importance_searchtext;
                        }
                    }

                    if($scope.search.objective_applications.length) {
                        criteria.objective_applications = $scope.search.objective_applications;
                        if($scope.search.importance_objective_applications > 1){
                            criteria.importance_objective_applications = $scope.search.importance_objective_applications;
                        }
                    }
                    if($scope.search.level_involvements.length) {
                        criteria.level_involvements = $scope.search.level_involvements;
                        if($scope.search.importance_level_involvements > 1){
                            criteria.importance_level_involvements = $scope.search.importance_level_involvements;
                        }
                    }
                    if($scope.search.geographical_scopes.length) {
                        criteria.geographical_scopes = $scope.search.geographical_scopes;
                        if($scope.search.importance_geographical_scopes > 1){
                            criteria.importance_geographical_scopes = $scope.search.importance_geographical_scopes;
                        }
                    }
                    if($scope.search.societal_challenges.length){
                        criteria.societal_challenges = $scope.search.societal_challenges;
                        if($scope.search.importance_societal_challenges > 1){
                            criteria.importance_societal_challenges = $scope.search.importance_societal_challenges;
                        }
                    }

                    if($scope.search.engaged_stakeholders_organisers.length){
                        criteria.engaged_stakeholders_organisers = $scope.search.engaged_stakeholders_organisers;
                        if($scope.search.importance_organisers > 1){
                            criteria.importance_organisers = $scope.search.importance_organisers;
                        }
                    }
                    if($scope.search.engaged_stakeholders_directparticipants.length){
                        criteria.engaged_stakeholders_directparticipants = $scope.search.engaged_stakeholders_directparticipants;
                        if($scope.search.importance_directparticipants > 1){
                            criteria.importance_directparticipants = $scope.search.importance_directparticipants;
                        }
                    }
                    if($scope.search.engaged_stakeholders_beneficiaries.length){
                        criteria.engaged_stakeholders_beneficiaries = $scope.search.engaged_stakeholders_beneficiaries;
                        if($scope.search.importance_beneficiaries > 1){
                            criteria.importance_beneficiaries = $scope.search.importance_beneficiaries;
                        }
                    }

                    if($scope.search.skill_subject_matters && $scope.search.skill_subject_matters!=415) {
                        criteria.skill_subject_matters = $scope.search.skill_subject_matters;
                        if($scope.search.importance_skill_subject_matters > 1) {
                            criteria.importance_skill_subject_matters = $scope.search.importance_skill_subject_matters;
                        }
                    }
                    if($scope.search.skill_its && $scope.search.skill_its !=419) {
                        criteria.skill_its = $scope.search.skill_its;
                        if($scope.search.importance_skill_its > 1) {
                            criteria.importance_skill_its = $scope.search.importance_skill_its;
                        }
                    }
                    if($scope.search.skill_facilitations && $scope.search.skill_facilitations!= 423) {
                        criteria.skill_facilitations = $scope.search.skill_facilitations;
                        if($scope.search.importance_skill_facilitations > 1) {
                            criteria.importance_skill_facilitations = $scope.search.importance_skill_facilitations;
                        }
                    }
                    if($scope.search.skill_events && $scope.search.skill_events != 427) {
                        criteria.skill_events = $scope.search.skill_events;
                        if($scope.search.importance_skill_events > 1) {
                            criteria.importance_skill_events = $scope.search.importance_skill_events;
                        }
                    }
                    if($scope.search.skill_project_managements && $scope.search.skill_project_managements !=431) {
                        criteria.skill_project_managements = $scope.search.skill_project_managements;
                        if($scope.search.importance_skill_project_managements > 1) {
                            criteria.importance_skill_project_managements = $scope.search.importance_skill_project_managements;
                        }
                    }

                    //practical
                    if($scope.search.practical_number_participants && $scope.search.practical_number_participants !=506) {
                        criteria.practical_number_participants = $scope.search.practical_number_participants;
                        if($scope.search.importance_practical_number_participants > 1) {
                            criteria.importance_practical_number_participants = $scope.search.importance_practical_number_participants;
                        }
                    }

                    if($scope.search.practical_retaining_participants && $scope.search.practical_retaining_participants != 451) {
                        criteria.practical_retaining_participants = $scope.search.practical_retaining_participants;
                        if($scope.search.importance_practical_retaining_participants > 1) {
                            criteria.importance_practical_retaining_participants = $scope.search.importance_practical_retaining_participants;
                        }
                    }

                    if($scope.search.practical_budget_needs && $scope.search.practical_budget_needs != 513) {
                        criteria.practical_budget_needs = $scope.search.practical_budget_needs;
                        if($scope.search.importance_practical_budget_needs > 1) {
                            criteria.importance_practical_budget_needs = $scope.search.importance_practical_budget_needs;
                        }
                    }

                    if($scope.search.practical_time_needed_for_execution_of_methods && $scope.search.practical_time_needed_for_execution_of_methods != 520) {
                        criteria.practical_time_needed_for_execution_of_methods = $scope.search.practical_time_needed_for_execution_of_methods;
                        if($scope.search.importance_practical_time_needed_for_execution_of_methods > 1) {
                            criteria.importance_practical_time_needed_for_execution_of_methods = $scope.search.importance_practical_time_needed_for_execution_of_methods;
                        }
                    }

                    if($scope.search.practical_duration_of_participant_involvements && $scope.search.practical_duration_of_participant_involvements != 527) {
                        criteria.practical_duration_of_participant_involvements = $scope.search.practical_duration_of_participant_involvements;
                        if($scope.search.importance_practical_duration_of_participant_involvements > 1) {
                            criteria.importance_practical_duration_of_participant_involvements = $scope.search.importance_practical_duration_of_participant_involvements;
                        }
                    }

                    if($scope.search.practical_contexts && $scope.search.practical_contexts != 555) {
                        criteria.practical_contexts = $scope.search.practical_contexts;
                        if($scope.search.importance_practical_contexts > 1) {
                            criteria.importance_practical_contexts = $scope.search.importance_practical_contexts;
                        }
                    }


                    //participants
                    if($scope.search.participants_gather_datas && $scope.search.participants_gather_datas !=446) {
                        criteria.participants_gather_datas = $scope.search.participants_gather_datas;
                        if($scope.search.importance_participants_gather_datas > 1) {
                            criteria.importance_participants_gather_datas = $scope.search.importance_participants_gather_datas;
                        }
                    }

                    if($scope.search.participants_analyse_datas && $scope.search.participants_analyse_datas !=447) {
                        criteria.participants_analyse_datas = $scope.search.participants_analyse_datas;
                        if($scope.search.importance_participants_analyse_datas > 1) {
                            criteria.importance_participants_analyse_datas = $scope.search.importance_participants_analyse_datas;
                        }
                    }

                    if($scope.search.participants_academic_experts && $scope.search.participants_academic_experts != 454) {
                        criteria.participants_academic_experts = $scope.search.participants_academic_experts;
                        if($scope.search.importance_participants_academic_experts > 1) {
                            criteria.importance_participants_academic_experts = $scope.search.importance_participants_academic_experts;
                        }
                    }

                    if($scope.search.participants_stakeholders && $scope.search.participants_stakeholders != 455) {
                        criteria.participants_stakeholders = $scope.search.participants_stakeholders;
                        if($scope.search.importance_participants_stakeholders > 1) {
                            criteria.importance_participants_stakeholders = $scope.search.importance_participants_stakeholders;
                        }
                    }

                    if($scope.search.participants_citizens && $scope.search.participants_citizens != 456) {
                        criteria.participants_citizens = $scope.search.participants_citizens;
                        if($scope.search.importance_participants_citizens > 1) {
                            criteria.importance_participants_citizens = $scope.search.importance_participants_citizens;
                        }
                    }

                    if($scope.search.participants_users && $scope.search.participants_users != 457) {
                        criteria.participants_users = $scope.search.participants_users;
                        if($scope.search.importance_participants_users > 1) {
                            criteria.importance_participants_users = $scope.search.importance_participants_users;
                        }
                    }

                    if($scope.search.participants_policy_makers && $scope.search.participants_policy_makers != 458) {
                        criteria.participants_policy_makers = $scope.search.participants_policy_makers;
                        if($scope.search.importance_participants_policy_makers > 1) {
                            criteria.importance_participants_policy_makers = $scope.search.importance_participants_policy_makers;
                        }
                    }

                    if($scope.search.participants_digital_skills && $scope.search.participants_digital_skills != 534) {
                        criteria.participants_digital_skills = $scope.search.participants_digital_skills;
                        if($scope.search.importance_participants_digital_skills > 1) {
                            criteria.importance_participants_digital_skills = $scope.search.importance_participants_digital_skills;
                        }
                    }

                    if($scope.search.participants_literacy_skills && $scope.search.participants_literacy_skills != 541) {
                        criteria.participants_literacy_skills = $scope.search.participants_literacy_skills;
                        if($scope.search.importance_participants_literacy_skills > 1) {
                            criteria.importance_participants_literacy_skills = $scope.search.importance_participants_literacy_skills;
                        }
                    }


                    //com
                    if($scope.search.com_legal_issues && $scope.search.com_legal_issues != 462) {
                        criteria.com_legal_issues = $scope.search.com_legal_issues;
                        if($scope.search.importance_com_legal_issues > 1) {
                            criteria.importance_com_legal_issues = $scope.search.importance_com_legal_issues;
                        }
                    }

                    if($scope.search.com_social_issues && $scope.search.com_social_issues != 463) {
                        criteria.com_social_issues = $scope.search.com_social_issues;
                        if($scope.search.importance_com_social_issues > 1) {
                            criteria.importance_com_social_issues = $scope.search.importance_com_social_issues;
                        }
                    }

                    if($scope.search.com_governance_issues && $scope.search.com_governance_issues != 464) {
                        criteria.com_governance_issues = $scope.search.com_governance_issues;
                        if($scope.search.importance_com_governance_issues > 1) {
                            criteria.importance_com_governance_issues = $scope.search.importance_com_governance_issues;
                        }
                    }

                    if($scope.search.com_ethics_explicits && $scope.search.com_ethics_explicits != 445) {
                        criteria.com_ethics_explicits = $scope.search.com_ethics_explicits;
                        if($scope.search.importance_com_ethics_explicits > 1) {
                            criteria.importance_com_ethics_explicits = $scope.search.importance_com_ethics_explicits;
                        }
                    }

                    if($scope.search.com_heterogeneities && $scope.search.com_heterogeneities != 459) {
                        criteria.com_heterogeneities = $scope.search.com_heterogeneities;
                        if($scope.search.importance_com_heterogeneities > 1) {
                            criteria.importance_com_heterogeneities = $scope.search.importance_com_heterogeneities;
                        }
                    }

                    if($scope.search.com_temporal_scopes && $scope.search.com_temporal_scopes != 465) {
                        criteria.com_temporal_scopes = $scope.search.com_temporal_scopes;
                        if($scope.search.importance_com_temporal_scopes > 1) {
                            criteria.importance_com_temporal_scopes = $scope.search.importance_com_temporal_scopes;
                        }
                    }

                    if($scope.search.com_transparency_of_engagements && $scope.search.com_transparency_of_engagements != 450) {
                        criteria.com_transparency_of_engagements = $scope.search.com_transparency_of_engagements;
                        if($scope.search.importance_com_transparency_of_engagements > 1) {
                            criteria.importance_com_transparency_of_engagements = $scope.search.importance_com_transparency_of_engagements;
                        }
                    }

                    if($scope.search.com_openness_of_agendas && $scope.search.com_openness_of_agendas != 466) {
                        criteria.com_openness_of_agendas = $scope.search.com_openness_of_agendas;
                        if($scope.search.importance_com_openness_of_agendas > 1) {
                            criteria.importance_com_openness_of_agendas = $scope.search.importance_com_openness_of_agendas;
                        }
                    }

                    if($scope.search.com_level_of_deliberations && $scope.search.com_level_of_deliberations != 469) {
                        criteria.com_level_of_deliberations = $scope.search.com_level_of_deliberations;
                        if($scope.search.importance_com_level_of_deliberations > 1) {
                            criteria.importance_com_level_of_deliberations = $scope.search.importance_com_level_of_deliberations;
                        }
                    }


                    qfactory.getSearch(criteria).then(
                        function(response) {
//                            console.log("heh"+$scope.datasearch["children"].length);
//                            for(i = 0; i < $scope.datasearch["children"].length; i++) {
//                                console.log($scope.datasearch["children"][i].size +"="+ response.data[$scope.datasearch["children"][i].id]);
//                                $scope.datasearch["children"][i].size = response.data[$scope.datasearch["children"][i].id];
//                            }
                            $scope.datasearch = response.data;

                            $scope.$emit('UNLOAD');
                        },
                        function(error) {
                            loaded_data = false;
                            console.log("The request failed: " + error);
                        });
                }

                $scope.sce = $sce;
						}],
				})
				.state('method', {
						url: "/method/{method_id}",
            title: "ActionCatalogue - method",
						templateUrl: "partials/method/{method_id}",
						controller: ['$scope', '$location', 'qFactory', '$sce', '$stateParams', '$anchorScroll', function($scope, $location, qfactory, $sce, $stateParams, $anchorScroll) {


//                $scope.scrollTo = function(id) {
//                    console.log($location.hash());
//                    $location.hash(id);
//                    $anchorScroll();
//                };
                $scope.gotoTitle = function(anchor) {
                    if ($location.hash() !== anchor) {
                        // set the $location.hash to `newHash` and
                        // $anchorScroll will automatically scroll to it
                        console.log("this is first");
                        $location.hash(anchor);
                    } else {
                        console.log("this is second");
                        // call $anchorScroll() explicitly,
                        // since $location.hash hasn't changed
                        $anchorScroll();
                    }
                };

                $scope.$on('LOAD', function() { $scope.loading = true; });
                $scope.$on('UNLOAD', function() { $scope.loading = false; });
                $scope.$emit('LOAD');

                qfactory.getItem($stateParams.method_id).then(
                    function(response) {
                        $scope.itemdata = response.data
                        $scope.$emit('UNLOAD');
                    },
                    function(error) {
                        loaded_data = false;
                        console.log("The request failed: " + error);
                    });

                $scope.sce = $sce;
            }]


				});

});

engageApp.run([ '$rootScope', '$state', '$stateParams',
function ($rootScope, $state, $stateParams) {
  $rootScope.$state = $state;
  $rootScope.$stateParams = $stateParams;
}]);
engageApp.run(['$anchorScroll', function($anchorScroll) {
  $anchorScroll.yOffset = 50;   // always scroll by 50 extra pixels
}])



engageApp.run(function ($rootScope, $location) {

    var history = [];

    $rootScope.$on('$routeChangeSuccess', function() {
        console.log("alertting");
        history.push($location.$$path);
    });
    console.log($location);
    $rootScope.back = function () {
        var prevUrl = history.length > 1 ? history.splice(-2)[0] : "/search";
        console.log(history.length);
        $location.path(prevUrl);
    };

});
